.App { 
  padding: 10px;
}

.title-container {
  display: flex;
}

.logo {
  width: 75px;
  height: 75px;
}

.box {
  border: 1px solid rgba(153,152,152,0.405);
  border-radius: 4px;
  padding: 50px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #FFF;
}

@media only screen and (min-width: 992px) {
  .box {
    width: 1000px;
  }
} 

@media only screen and (max-width: 600px) {
  .box {
    padding: 15px;
  }
}

.button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.title {
  background: linear-gradient(to left, #f8ce22, #f7c213, #e0b011);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.score {
  font-family: \"PlusJakartaSans-ExtraBold\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\";
  color: rgb(107, 109, 111);
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  font-size: 1.4em;
  transition: all 0.3s ease;
}

.emphasized {
  font-weight: bold;
}